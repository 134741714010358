import Vue from "vue";
import VueRouter from "vue-router";

//编程式导航重复跳转同一路由报错bug解决
let push = VueRouter.prototype.push;
let replace = VueRouter.prototype.replace;
//重写VueRouter.prototype原型对象的push|replace方法
VueRouter.prototype.push = function (location) {
  //调用push方法且篡改push方法内部this指向->vueRouter类的实例
  push.call(
    this,
    location,
    () => {},
    () => {}
  );
};
VueRouter.prototype.replace = function (location) {
  //调用push方法且篡改push方法内部this指向->vueRouter类的实例
  replace.apply(this, [location, () => {}, () => {}]);
};

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("../views/home/Home.vue"),
    },
    {
      path: "/jiaruwomen",
      name: "jiaruwomen",
      component: () => import("../views/jiaruwomen/index.vue"),
    },
    {
      path: "/xinjiyuanjianjie",
      name: "xinjiyuanjianjie",
      component: () => import("../views/xinjiyuanjianjie/index.vue"),
    },
    {
      path: "/xinlifuwushizituandui",
      name: "xinlifuwushizituandui",
      component: () => import("../views/xinlifuwushizituandui/index.vue"),
    },
    {
      path: "/xinlifuwuxinlikecheng",
      name: "xinlifuwuxinlikecheng",
      component: () => import("../views/xinlifuwuxinlikecheng/index.vue"),
    },
    {
      path: "/xinlifuwuxinlizixun",
      name: "xinlifuwuxinlizixun",
      component: () => import("../views/xinlifuwuxinlizixun/index.vue"),
    },
    {
      path: "/xinlifuwuxinlizixunlaoshixiangqing",
      name: "xinlifuwuxinlizixunlaoshixiangqing",
      component: () =>
        import("../views/xinlifuwuxinlizixunlaoshixiangqing/index.vue"),
    },
    {
      path: "/xinlikepu",
      name: "xinlikepu",
      component: () => import("../views/xinlikepu/index.vue"),
    },
    {
      path: "/xinlikepuxiangqing",
      name: "xinlikepuxiangqing",
      component: () => import("../views/xinlikepuxiangqing/index.vue"),
    },
    {
      path: "/xinlipingce",
      name: "xinlipingce",
      component: () => import("../views/xinlipingce/index.vue"),
    },
    {
      path: "/xinlipingceceshijieguo",
      name: "xinlipingceceshijieguo",
      component: () => import("../views/xinlipingceceshijieguo/index.vue"),
    },
    {
      path: "/xinlipingcekaishiceshi",
      name: "xinlipingcekaishiceshi",
      component: () => import("../views/xinlipingcekaishiceshi/index.vue"),
    },
    {
      path: "/xinlipingceyindaoye",
      name: "xinlipingceyindaoye",
      component: () => import("../views/xinlipingceyindaoye/index.vue"),
    },
    {
      path: "/xueyuanjianjiejuexinyuan",
      name: "xueyuanjianjiejuexinyuan",
      component: () => import("../views/xueyuanjianjiejuexinyuan/index.vue"),
    },
    {
      path: "/zhengshuchaxun",
      name: "zhengshuchaxun",
      component: () => import("../views/zhengshuchaxun/index.vue"),
    },
    {
      path: "/ydHome",
      name: "ydHome",
      component: () => import("../views/ydHome/index.vue"),
    },
    {
      path: "/ydJiaruxinjiyuan",
      name: "ydJiaruxinjiyuan",
      component: () => import("../views/ydJiaruxinjiyuan/index.vue"),
    },
    {
      path: "/ydXinjiyuanxinliyewu",
      name: "ydXinjiyuanxinliyewu",
      component: () => import("../views/ydXinjiyuanxinliyewu/index.vue"),
    },
    {
      path: "/ydXinliceping",
      name: "ydXinliceping",
      component: () => import("../views/ydXinliceping/index.vue"),
    },
    {
      path: "/ydZhengshuchaxun",
      name: "ydZhengshuchaxun",
      component: () => import("../views/ydZhengshuchaxun/index.vue"),
    },
    {
      path: "/meeting",
      name: "meeting",
      component: () => import("../views/meeting/index.vue"),
    },
    {
        path: "/shoukuan",
        name: "shoukuan",
        component: () => import("../views/shoukuan/index.vue"),
      },
    // ydXinjiyuanxinliyewu
  ],
});
