<template>
  <div>
    <router-view />
  </div>
</template>

<script>
// 假设当前 URL 是: https://example.com?name=John&age=30  
const locUrl = new URL(window.location.href);
const pageParams = new URLSearchParams(locUrl.search);


export default {
  name: 'app',
  components: {
  },
  data () {
    return {
      pageParams: {}
    }
  },
  created () {
    const locUrl = new URL(window.location.href);
    this.pageParams = new URLSearchParams(locUrl.search);
    console.log(111111,this.pageParams)
  },
  mounted () {
    function isMobileDevice () {
      // 获取用户的User-Agent字符串
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // 常见的移动设备User-Agent片段
      let mobileAgents = [
        "Android",
        "iPhone",
        "iPad",
        "iPod",
        "BlackBerry",
        "Windows Phone",
        "IEMobile",
        "Opera Mini",
        "Mobile",
        "mobile",
        "Mobile Safari",
        "Opera Mobi",
        "Silk",
        "Kindle",
        "Silk-Accelerated",
      ];

      // 检查User-Agent是否包含任何移动设备的片段
      for (let i = 0; i < mobileAgents.length; i++) {
        if (userAgent.indexOf(mobileAgents[i]) > -1) {
          return true; // 是移动设备
        }
      }

      // 如果没有匹配到任何移动设备片段，则假定为非移动设备
      return false;
    }

    // 使用示例
    if (isMobileDevice()) {
      this.$router.push({ path: "/meeting", query: { params: this.pageParams.meetingId} });
    } else {
      console.log("这不是一个移动设备访问");
    }
  },
}
</script>

<style>
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-dispaly {
  display: flex;
}
.flex-warp {
  display: flex;
  flex-wrap: wrap;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.align-center {
  display: flex;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
.router-link-active {
  text-decoration: none;
  color: yellow;
}
</style>
